@use "../../scss/" as *;

button {
    
    cursor: pointer;
    font-family: $font-family;




}

.btn {

    border: 4px solid transparent;
    background-color: $main-color;
     color: $white;
     border-radius: $border-radius;
     padding: 0.5rem 1.8rem;
     font-size: 1.5rem;
     font-weight: 600;
     box-shadow: 0px 0px 7px 8px #0000004d;
     transition: box-shadow 0.3s ease;
     position: relative;

}

.btn:hover{

    box-shadow:  0px 0px 7px 15px #0000004d;
    background-color: $white;
    color: $main-color;
}

 .btn-outline {
    border: 4px solid transparent;
    background-color: $main-color;
     color: $white;
     border-radius: $border-radius;
     padding: 0.5rem 1.8rem;
     font-size: 1.5rem;
     font-weight: 600;
     box-shadow: 0px 0px 7px 8px #0000004d;
     transition: box-shadow 0.3s ease;
     position: relative;
     left: 1rem;


@include mobile {
    top: 0.3rem;
    left: -0.1em;
}

 }


 .btn-outline:hover {
     box-shadow: unset;
     color: $main-color;
     background-color: $white;
 }

 .btn.small {
     border-width: 2px;
     padding: 0.25rem 1.5rem;
     font-size: 1rem;
 }

 