@use "../../scss/" as *;

.hero-slide {
    margin-bottom: 3rem;
}

.hero-slide__item {
    padding: 9rem 0;
    width: 100%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;


    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include overlay();
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $body-bg, rgba($black, 0));

}

&__content {
    @include flex(center, center);
    position: relative;

    &__info {
        width: 55%;
        padding: 0 3rem;
        position: relative;

        @include tablet {
            width: 100%;
        }

    & > * ~ * {
        margin-top: 3rem;
    } 

        .title {
            font-size: 5rem;
            font-weight: 700;
            line-height: 1;

              @include tablet {
                  font-size: 4rem
              }

             
              
        }}
    
    
    
    }}

         