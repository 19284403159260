@use './scss/' as *;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

* {
         padding: 0;
         margin: 0;
         box-sizing: border-box;
         -webkit-tap-highlight-color: transparent;


}

html { 
    font-size: 100%;

    @include tablet {
        font-size: 80%;
    }

    @include mobile {
        font-size: 60%;
    }
}

body {
    font-family: $font-family;
    font-weight: 400;
    line-height: 1,5;

    background-color: $body-bg;
   color: $txt-color;

    @include mobile {
        margin-bottom: 3rem;
    }

a{
    text-decoration: none;
    color: unset;
     
}

@media (hover: hover){
    a:hover {
        color: $main-color;
    }

}

button,
input {
    outline: 0;
}

ul {
    list-style-type: none;

}

img{
    max-width: 100%;
}
iframe{
    border: 0;
}
.container {
    max-width: 1660px;
    margin: auto;

}

.mb-3 {
    margin-bottom: 3rem;
}

.mb2 {
    margin-bottom: 2rem;
}
.section {
    padding: 0 2 rem;
}

.section__header{
    @include flex(center, space-between);
}
}