@mixin flex ($align-item, $justify-conent) {
    display: flex;
    align-items: $align-item;
    justify-content: $justify-conent;

}

@mixin overlay {
    
    background-color: rgba($color: #000000, $alpha: 0.6);
    
}