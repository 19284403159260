@use "../../scss/" as *;

.logo {
    font-size: 2rem;
    font-weight: 600;


@include flex(center, unset);

img {
    margin-right:  10px;
    width: 150px;
    margin-top: 5px;


@include mobile {
    width: 50px;
    margin-right: 0;
    
}
}
}

.header {
    height: $header-height;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: height .3s ease, background-color .3s ease;


    &.shrink {
        height: $header-shrink-height;
        background-color: body-bg;
    }

&__wrap {
   @include flex(center,space-between); 
   height: 100%;
   padding: 0 2rem;

   @include mobile {
       justify-content: center;
   }

   
}

&__nav {
    @include flex(center,unset) ;

    & > * ~ * {
        margin-left: 2rem;
    }
    
    li {

        padding:  5px 0;
        font-weight: 700;
        position: relative;
        font-size: 1.3rem;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            transition: width .5s ease;
            height: 2px;
            background-color: $main-color;
        }
        &.active::after,
         &:hover::after {
            width: 100%;
        }

    }

   @include mobile {
       position: fixed;
       bottom: 0;
       left: 0;
       height: 5rem;
       width:  100%;
       background-color: $body-bg;
       padding: 0 2rem;
       box-shadow: $box-shadow;

       @include flex(center, space-between);
       & > * ~ * {
           margin-left: 0;
       }
   }
}

}